.baseTitle {
  margin-bottom: 15px;
  width: 100%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  background-color: #1890ff;
}
.buttonWrapper {
  text-align: center;
}