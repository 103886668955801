.registerExpert {
  width: 100%;
  height: 100%;
  // background-color: #eee;
  background: url(../../assets/images/bg2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  .loginLog {
    position: relative;
    width: 100%;
    height: 90px;

    // background-color: blue;
    .logoImage {
      position: absolute;
      width: 320px;
      height: 60px;
      left: 15px;
      top: 20px;
    }
  }

  .expertBox {
    @media screen and (min-width: 1024px) {
      padding: 10px 20%;
    }
    @media screen and (max-width: 500px) {
      padding: 10px 20px;
    }
    // padding: 10px 20%;
    width: 100%;
    height: calc(100% - 170px);
    background-color: #fff;
    .expertStep {
      @media screen and (min-width: 575px) {
        padding: 10px 20%;
        height: 75px;
      }
      @media screen and (max-width: 575px) {
        padding: 10px 20px;
        height: 200px;
      }
      // padding: 0 18%;
      // width: 100%;
      // height: 75px;

      .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
        max-width: 200px;
      }
    }

    .expertContent {
      padding: 10px 18px 20px;
      width: 100%;
      height: calc(100% - 190px);
      // background-color: #EEF7FD;
      border-radius: 5px;
      overflow-y: auto;

      .constitutionConfirm {
        margin-top: 10px;
        width: 100%;
        text-align: center;
        border-top: 1px solid #1890ff;

        .checkBox {
          margin: 10px 0;
          width: 100%;
        }

        .registerBtn {
          width: 150px;
        }
      }

      .tableConfirm {
        width: 100%;

        .baseTitle {
          // padding: 0 40px;
          margin-bottom: 15px;
          width: 100%;
          height: 30px;
          text-align: center;
          line-height: 30px;
          color: #fff;
          background-color: #1890ff;
          // border-bottom: 1px solid ;
        }

        .buttonItem {
          width: 100%;
          text-align: center;
        }

        .ant-upload-list-item {
          border-color: #d9d9d9;

          .ant-upload-list-item-name {
            color: #1890ff;
          }

          .anticon-delete {
            color: #1890ff;
          }
        }

        .ant-tooltip {
          display: none;
        }
      }

      .contentThreeButton {
        margin-top: 40px;
        width: 100%;
        text-align: center;
      }
    }
  }

  .loginFooter {
    padding-top: 20px;
    width: 100%;
    height: 80px;
    text-align: center;

    // background-color: blue;
    .footerSign {
      color: #fff;
    }
  }

  .hideContent {
    display: none;
  }

  .checked {
    background-color: #1890ff;
    color: #fff;
  }

  .noChecked {
    background-color: #ddd;
    color: #fff;
  }
}

.unitName {
  width: 100%;
  display: block;

  .ant-col-xs-12 {
    max-width: 100%;
  }
}

// 超小设备 手机 768px以下
// @media screen and (max-width: 768px) {

//   html,
//   body {
//     font-size: calc(100vw / 7.5);
//   }

//   .registerExpert {
//     width: 100%;
//     height: 100%;
//     background: url(../../assets/images/bg2.jpg) top center/cover no-repeat;

//     .loginLog {
//       height: 0.9rem;

//       .logoImage {
//         width: 3.2rem;
//         height: 0.6rem;
//         left: 0.15rem;
//         top: 0.2rem;
//       }
//     }

//     .expertBox {
//       padding: 0.1rem 0.4rem;
//       height: calc(100% - 1.7rem);

//       .expertStep {
//         padding: 0;
//         height: 0.75rem;
//         .ant-steps-vertical {
//           flex-direction: row;
//           zoom: 0.65;
//         }
//         .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
//           max-width: 2rem;
//         }
//       }

//       .expertContent {
//         padding: 0.1rem 0.18rem 0.2rem;
//         height: calc(100% - 0.75rem);
//         border-radius: 0.05rem;

//         .expertNotice {
//           .contentOneHeader {
//             font-size: 0.4rem;
//           }
//           .contentOneOne {
//             font-size: 0.3rem;
//           }
//         }

//         .constitutionConfirm {
//           margin-top: 0.1rem;

//           .checkBox {
//             margin: 0.1rem 0;
//             .ant-checkbox-wrapper {
//               font-size: 0.26rem;
//             }
//           }
//           .registerBtn {
//             width: 1.5rem;
//             font-size: 0.26rem;
//           }
//         }

//         .tableConfirm {
//           width: 100%;

//           .baseTitle {
//             margin-bottom: 0.3rem;
//             height: 0.6rem;
//             line-height: 0.6rem;
//           }
//         }

//         .contentThreeButton {
//           margin-top: 0.4rem;
//         }
//       }
//     }

//     .loginFooter {
//       padding-top: 0.2rem;
//       height: 0.8rem;

//       .footerSign {
//         font-size: 0.14rem;
//         position: absolute;
//         left: 50%;
//         transform: translateX(-50%);
//       }
//     }

//   }

//   .contentThree {
//     padding: 0.2rem 1rem 0;
//     .contentThreeOne {
//       font-size: 0.2rem;
//     }
//     .contentThreeTwo {
//       font-size: 0.18rem;
//     }
//   }

//   .backBtn {
//     display: none;
//   }

// }
