html,
body,
#root,
.ant-layout {
  width: 100%;
  height: 100%;

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      height: 100%;

      .ant-layout {
        height: calc(100% - 64px);

        .ant-layout {
          height: 100%;
        }
      }
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;

  // background-color: #3580BC;
  .right-box {
    display: flex;

    .right-one {
      margin-right: 28px;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
    }

    .logOut {
      margin-right: 28px;
      font-size: 16px;
      color: red;
      cursor: pointer;
    }
  }
}

.memner-header {
  background-color: #3580BC;
}

.ant-layout-header {
  padding: 0 15px;
}

.site-layout-background {
  // margin-bottom: 30px;
  width: 100%;
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  // overflow-y: scroll;
  .site-image {
    padding: 50px 50px 30px;
    width: 100%;

    .site-avatar {
      width: 100%;
      height: 180px;
      text-align: center;

      img {
        width: 150px;
        height: 150px;
        border-radius: 150px;
      }
    }

    .avatar-info {
      margin-bottom: 10px;
      width: 100%;
      text-align: center;
      color: #333;
    }

    .site-title {
      display: flex;
      padding: 0 30px;
      width: 100%;
      color: #333;
      font-size: 14px;
      justify-content: space-around;

      span {
        cursor: pointer;
      }

      a {
        color: rgba(0, 0, 0, 0.85);
        transition: all 0.3s;
      }

      a:hover {
        color: #1890ff;
      }
    }
  }
}

.logOut {
  margin-right: 28px;
  font-size: 16px;
  color: red;
  cursor: pointer;
}

// .test {
//   margin: 10px;
//   width: 100%;
//   height: 30px;
//   background-color: red;
//   // box-sizing: border-box;
// }