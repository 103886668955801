.register {
  width: 100%;
  height: 100%;
  // background-color: #eee;
  background: url(../../assets/images/bg2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
  .loginLog {
    position: relative;
    width: 100%;
    height: 90px;
    // background-color: blue;
    .logoImage {
      position: absolute;
      width: 320px;
      height: 60px;
      left: 15px;
      top: 20px;
    }
  }
  .registerContent {
    @media screen and (min-width: 1024px) {
      padding: 10px 20%;
    }
    @media screen and (max-width: 500px) {
      padding: 10px 20px;
    }
    width: 100%;
    // height: calc(100% - 170px);
    background-color: #fff;
    .registerBox {
      padding: 10px;
      width: 100%;
      height: 100%;
      // background-color: #EEF7FD;
      border-radius: 5px;
      .registerHeader {
        margin-bottom: 10px;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        color: #333;
      }
      .registerContentOne {
        font-size: 15px;
        line-height: 1.7;
        margin-bottom: 15px;
        .contenrOneTitle {
          margin-left: 20px;
          font-weight: 700;
        }
        .flowImage {
          color: red;
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .registerContentTwo {
        margin-bottom: 15px;
        display: flex;
        font-size: 15px;
        line-height: 1.7;
        justify-content: space-around;
        .contact {
          flex: 2;
        }
        .account {
          flex: 1;
        }
        .contentTwoTitle {
          margin-left: 20px;
          font-weight: 700;
        }
      }

      .registerContentThree {
        display: flex;
        padding: 20px;
        width: 100%;
        justify-content: space-around;
        .registerThree {
          display: flex;
          width: 150px;
          height: 60px;
          align-items: center;
          border-radius: 4px;
          background-color: #eef7fd;
          &.disabled{
            filter: grayscale(100%);
            color: #999999;
            cursor: not-allowed;
            .registerTitle{
              cursor: not-allowed;
            }
          }
          .registerImage {
            flex: 1;
            text-align: center;
            align-items: center;
            // line-height: 60px;
            @media screen and (max-width: 500px) {
              .unit {
                font-size: 14px;
                color: blue;
              }
              .person {
                font-size: 14px;
                color: blue;
              }
            }
            // .unit {
            //   font-size: 28px;
            //   color: blue;
            // }
            // .person {
            //   font-size: 28px;
            //   color: blue;
            // }
          }
          .registerTitle {
            flex: 1;
            margin-top: 5px;
            line-height: 1.7;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }
  .loginFooter {
    padding-top: 20px;
    width: 100%;
    height: 80px;
    text-align: center;
    // background-color: blue;
    .footerSign {
      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
      color: #fff;
    }
  }
}
