.registerUnit {
  width: 100%;
  height: 100%;
  // background-color: #eee;
  background: url(../../assets/images/bg2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
  .loginLog {
    position: relative;
    width: 100%;
    height: 90px;
    // background-color: blue;
    .logoImage {
      position: absolute;
      width: 320px;
      height: 60px;
      left: 15px;
      top: 20px;
    }
  }
  .unitBox {
    @media screen and (min-width: 575px) {
      padding: 10px 20%;
    }
    @media screen and (max-width: 575px) {
      padding: 10px 20px;
    }
    height: calc(100% - 170px);
    background-color: #fff;
    .unitStep {
      @media screen and (min-width: 575px) {
        padding: 10px 20%;
        height: 75px;
      }
      @media screen and (max-width: 575px) {
        padding: 10px 20px;
        height: 200px;
      }
      // padding: 10px 18%;
      width: 100%;
      // height: 75px;
      .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
        max-width: 200px;
      }
    }
    .unitContent {
      padding: 10px 30px 20px;
      width: 100%;
      height: calc(100% - 190px);
      // background-color: #EEF7FD;
      border-radius: 5px;
      overflow-y: auto;
      .constitutionConfirm {
        margin-top: 10px;
        width: 100%;
        text-align: center;
        border-top: 1px solid #1890ff;
        .checkBox {
          margin: 10px 0;
          width: 100%;
        }
      }
      .tableConfirm {
        // margin-bottom: 20px;
        width: 100%;
        .baseTitle {
          // padding: 0 40px;
          margin-bottom: 15px;
          width: 100%;
          height: 30px;
          text-align: center;
          line-height: 30px;
          color: #fff;
          background-color: #1890ff;
          // border-bottom: 1px solid ;
        }
        .buttonItem {
          padding: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .contentThreeButton {
        margin-top: 40px;
        width: 100%;
        text-align: center;
      }
    }
  }
  .loginFooter {
    padding-top: 20px;
    width: 100%;
    height: 80px;
    text-align: center;
    // background-color: blue;
    .footerSign {
      color: #fff;
    }
  }
  .hideContent {
    display: none;
  }
  .checked {
    background-color: #1890ff;
    color: #fff;
  }
  .noChecked {
    background-color: #ddd;
    color: #fff;
  }
}
