html,
body,
#root,
.ant-layout {
  width: 100%;
  height: 100%;
}

.adminContent {
  padding: 16px;
  height: calc(100% - 64px);
  overflow-y: auto;
}

.layout-content .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.layout-content .trigger:hover {
  color: #1890ff;
}

.layout-content .logo {
  height: 32px;
  // background: rgba(255, 255, 255, 0.3);
  margin: 16px;
  font-weight: 500;
  overflow: hidden;
}

.layout-content .site-layout .site-layout-background {
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.logOut {
  margin-right: 28px;
  font-size: 16px;
  color: red;
  cursor: pointer;
}

// .test {
//   margin: 10px;
//   width: 100%;
//   height: 30px;
//   background-color: red;
//   // box-sizing: border-box;
// }