.expertNotice {
  width: 100%;
  .contentOneHeader {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    // line-height: 2.4;
  }
  .contentOneOne {
    font-size: 16px;
    line-height: 2.4;
    .paragraphContent {
      margin-left: 20px;
    }
    .paragraphTitle {
      // font-size: 16px;
      font-weight: 600;
    }
  }
}