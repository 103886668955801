.constitution {
  width: 100%;
  .contentOneHeader {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }
  .contentOneOne {
    line-height: 2.4;
    font-size: 16px;
    .contentOneSubhead {
      font-weight: 600;
      text-align: center;
    }
    .paragraphTitle {
      // font-size: 16px;
      margin-right: 10px;
      font-weight: 600;
    }
  }
}