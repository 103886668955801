/* @import '../src//assets/css/iconfont/iconfont.css' */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body .repair {
  height: 100%;
  width: 100%;
  background: url('./assets/images/repair.jpg') no-repeat center;
  background-size: auto;
}

body iframe {
  display: none;
}

body iframe#myIframe {
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout-content {
  height: 100%;
}

/* 滚动条样式 */
::-webkit-scrollbar {
  /* width: 6px;
  height: 10px */
  /* background-color: transparent; */
  /* border-radius: 10px; */
  /* position: absolute;
  right: 20px; */
}

/*定义滚动条轨道 内阴影+圆角*/
/* ::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #fff;
} */

/*定义滑块 内阴影+圆角*/
/* ::-webkit-scrollbar-thumb {
  border-radius: 10px;
} */