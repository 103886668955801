.contentThree {
  padding: 80px 0 80px 0;
  @media screen and (max-width: 1100px) {
    padding-left: 200px;
  }
  @media screen and (min-width: 800px) {
    padding-left: 60px;
  }

  width: 100%;
  .contentThreeOne {
    line-height: 2.6;
    font-size: 18px;
    font-weight: 600;
  }
  .contentThreeTwo {
    line-height: 2.6;
    font-size: 16px;
  }
}
