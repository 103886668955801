.resultBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .resultContent {
    margin-top: -300px;
    .agreeDr {
      margin: 0 4px;
      padding: 2px 6px;
      color: #fff;
      background: #1677ff;
      border-radius: 5px;
    }
    .disAgreeDr {
      margin: 0 4px;
      padding: 2px 6px;
      color: #fff;
      background: #ff4d4f;
      border-radius: 5px;
    }
  }
  .ant-result-title {
    font-size: 30px;
  }
  .ant-result-subtitle {
    font-size: 16px;
  }
}
