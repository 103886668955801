.paragraph {
  line-height: 2;
  .paragraphTitle {
    font-size: 16px;
    margin-left: 10px;
    font-weight: 600;
  }
  .contactPeson {
    font-size: 16px;
    margin-right: 10px;
  }
}